import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({location}) => (
    <div>
        <SEO
            pagetitle="404ページのタイトル"
            pagedesc="404このページの説明"
            pagepath={location.pathname}
        />
        <Layout>
            <h1>お探しのページは見つかりませんでした。</h1>
        </Layout>
    </div>
)